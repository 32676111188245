$(document).on('ready', function () {
	$('body').addClass('CustomProfile');

	/*  Lightbox
	----------------------------------------------------------------- */
	$(".inline").modaal();
	$("a.Lightbox-for-image").modaal({ type: 'image' });
	$("a.Lightbox-for-video").modaal({ type: 'video' });
	$("a.Lightbox-for-iframe").modaal({ 
		type: 'iframe',
		width: 1000
	});

		/* JUMPLINKS: JumpLinks overall
	----------------------------------------------------------------- */
	var sections = $('.JumpSections'), //setting variable for Sections
		nav = $('#JumpNav'), //setting variable for the container of the jump links (to be used for height)
		navHeight = nav.outerHeight(); //height of the jumpnav

	/* JUMPLINKS: Dr sticky nav & active state on scroll
	----------------------------------------------------------------- */
	$(window).on('scroll', function () {
		if ($('#JumpNav ul').length > 0) {
			var stickyNavTop = $("#JumpNavWrap").offset().top, //top of jumpnav section
				winScrollTop = $(window).scrollTop() + nav.outerHeight(), //position to stop below fixed main nav
				curPos = $(this).scrollTop() + navHeight;

			navHeight = nav.outerHeight(); //height of jump menu for desktop

			//desktop screen size
			if ($("#JumpNav ul").length) {
				if (winScrollTop > stickyNavTop) {
					$('#JumpNavWrap').css('height', ($('#JumpNav').outerHeight()));
					$('#JumpNav').addClass('Fixed');
				} else {
					$('#JumpNav').removeClass('Fixed');
					$('#JumpNavWrap').removeAttr('style');
				}
				//active state on jump nav on scroll (this creates the active style) - larger screens
				curPos = $(this).scrollTop() + navHeight + 1;
				nav.find('a').removeClass('active');
				nav.find('button').removeClass('active');
				sections.each(function () {
					var top = $(this).offset().top,
						bottom = top + $(this).outerHeight();
					if (curPos >= top && curPos < bottom - 1) {
						nav.find('a[href="#' + $(this).attr('id') + '"]').addClass('active');
						nav.find('button.'+ $(this).attr('id') +'').addClass("active");
					}
				});
			}
		}
	});

	/* JUMPLINKS: click functionality to activate scroll on jump nav - larger screens
	----------------------------------------------------------------- */
	$('#JumpNav').on('click', 'ul li a', function (e) {
		e.preventDefault();
		var offset = $('#JumpNav').innerHeight();
		var target = this.hash;
		if ($(this).data('offset') != undefined) offset = $(this).data('offset');
		$('html, body').stop().animate({
			'scrollTop': $(target).offset().top - offset
		}, 500, 'swing', function() {
			// window.location.hash = target;
		});
	});

	// highlight current day of week for business hours 
	var FindDate = new Date();
	var WeekDays = FindDate.getDay();
	$('.LocationInfo .Hours .DaySchedule, .LocationInfo .Hours .HoursOfOperation').eq(WeekDays).addClass('TodaysWeekday');
	if (WeekDays == 0) {
		$('.Day:contains("Sunday"), .DayOfWeek:contains("Sunday")').addClass('Today');
	} else if (WeekDays == 1) {
		$('.Day:contains("Monday"), .DayOfWeek:contains("Monday")').addClass('Today');
	} else if (WeekDays == 2) {
		$('.Day:contains("Tuesday"), .DayOfWeek:contains("Tuesday")').addClass('Today');
	} else if (WeekDays == 3) {
		$('.Day:contains("Wednesday"), .DayOfWeek:contains("Wednesday")').addClass('Today');
	} else if (WeekDays == 4) {
		$('.Day:contains("Thursday"), .DayOfWeek:contains("Thursday")').addClass('Today');
	} else if (WeekDays == 5) {
		$('.Day:contains("Friday"), .DayOfWeek:contains("Friday")').addClass('Today');
	} else {
		$('.Day:contains("Saturday"), .DayOfWeek:contains("Saturday")').addClass('Today');
	}

	if ($('.CustomProfile #LocationCTA h3').length) {
		$('.CustomProfile #LocationCTA h3').prependTo('.CustomProfile #LocationCTA .ERWTRow');
		$('.CustomProfile #LocationCTA h3').addClass('WaitTimeLabel');
	}

	if (!$('#Services .TwoFlex, #Services .ThreeFlex, #Services .FourFlex').length) {
	
		if ($('#Services img').length) {
			$('#Services').wrapInner('<div class="Left" />');
			$('#Services .Left img:first').wrap('<div class="Right" />');
			$('#Services .Left .Right').appendTo('#Services');
			$('#Services .Button').appendTo('#Services .Left');
		} else if (($('#Services p').length) || ($('#Services ul').length)) {
			$('#Services').wrapInner('<div class="PageWidth NoImage" />');
			$('#Services .Button').appendTo('#Services .NoImage');
		}
		
		if ($('#Services .facetServiceLine ul').children().length > 13 ) {
			$('#Services .facetServiceLine ul').addClass('three-col');
		} else if ($('#Services .facetServiceLine ul').children().length > 7 ) {
			$('#Services .facetServiceLine ul').addClass('two-col');
		}
		
		if ($('#Services h2').length === 0) {
			$('#Services .PageWidth, #Services .Left').prepend('<h2>Services</h2>');
		}
	}

	if ($('#Services .TwoFlex, #Services .ThreeFlex, #Services .FourFlex').length) {
		$('#Services').addClass('HasFlexBoxes');
		$('.HasFlexBoxes').wrapInner('<div class="ServicesInnerWrap" />');
		if ($('#Services h2').length === 0) {
			$('#Services .ServicesInnerWrap').prepend('<h2>Services</h2>');
		}
	}

	var LocationHeight = $('.LocationWrapper').height();
	$('.MapWrapper').css('height', LocationHeight);
	
	if ($('#LocationLogo').length) {
		$('#Logo .cmsPanelContent').html($('#LocationLogo'));
	}
	
	if ($('.Events-Upcoming-Calendar').length) {
		$('.Events-Upcoming-Calendar .MoreLink a').text('View all meetings and seminars');
	}
	if ($('#SmartPanelNewsRecent').length) {
		$('#BelowPageContent .SmartPanel .Thumbnail img').attr('alt', '');
		$('#SmartPanelNewsRecent .MoreLink a').text('View all news and press releases');
	}
	if ($('#SmartPanelPagesRelated').length) {
		$('#SmartPanelPagesRelated .MoreLink a').text('View all news and press releases');
	}
	
	googleMapHeight();

});


// map height
function googleMapHeight() {
	"use strict";
	if ($(window).width() > 1024) {
		var LocationHeight = $('.LocationWrapper').height();
		$('.MapWrapper, #ClientLocationsMap, .MapWrapper iframe, #map, .GMap').css('height', LocationHeight);
	} else {
		$('.MapWrapper, #ClientLocationsMap, .MapWrapper iframe, #map, .GMap').css('height', '320px');
	}
}
 
// window resize event
var waitForFinalEvent = (function () {
var timers = {};
return function (callback, ms, uniqueId) {
	if (!uniqueId) {
	  uniqueId = "unc-location";
	}
	if (timers[uniqueId]) {
	  clearTimeout (timers[uniqueId]);
	}
	timers[uniqueId] = setTimeout(callback, ms);
};
})();

var width = $(window).width();
$(window).on('resize', function() {
	'use strict';
	waitForFinalEvent(function(){
		if($(this).width() !== width) {
			width = $(this).width();
			googleMapHeight();
		} else {
			//do nothing;
		}
	}, 250, "unc-location");
	
});

// Google Map Javascript API Key
$(function (environment, apiKey) {
	'use strict';
	var siteURL = window.location.hostname;

	if(siteURL.indexOf('devs') > 0 || siteURL.indexOf('dev') > 0){
		environment = 'dev';
		apiKey = 'AIzaSyCk7Nwee6M4gXITTHVbeSEgrnCR-kMKWxs';
	}
	else if(siteURL.indexOf('stage') > 0){
		environment = 'stage';
		apiKey = 'AIzaSyCk7Nwee6M4gXITTHVbeSEgrnCR-kMKWxs';
	}
	else{
		environment = 'live';
		apiKey = 'AIzaSyD3d9WsG6f5gxpNhx9AT9zI7A5PrVmlPWc';
	}
	
    // we don't want to include the api key on a page twice, 
    // so if this is a location detail page OR a location Razor view, do NOT add the api key because it's already pulled in by Core
    if ($('.cmsPage #LocationsDetail').length || $('div.CustomProfile.Razor').length) {
        //do nothing
    } else {
        $("<script src='https://maps.googleapis.com/maps/api/js?key="+apiKey+"' async defer>").appendTo('head');
    }
});

function initializeMap(locations) {
	// Map will center on the first location entered
	var firstlocation = locations[0];
	var firstlatlng = ({lat: firstlocation[1], lng:firstlocation[2]});
	var map = new google.maps.Map(document.getElementById('map'), {
	  zoom: 8,
	  center: firstlatlng
	});
	setMarkers(locations, map);
}

function setMarkers(locations, map) {
	// if all the locations have the exact same address we will omit the 'fitBounds'. Ensure we have different locations.
	var differentAddress = false;
	var latitudes = [];
	var longitudes = [];
	
	// create new google map info window
	var infowindow = new google.maps.InfoWindow(), marker, i;
	
	// bounds will make sure that all the markers on the map fit within the viewport
	var bounds = new google.maps.LatLngBounds();
	
	// Adds markers to the map
	for (var i = 0; i < locations.length; i++) {
		var location = locations[i];

		// if we have more than one location check to see if they have different addresses
		if(i > 0) {
			if($.inArray(location[1], latitudes) === -1 && $.inArray(location[2], longitudes) === -1) {
				differentAddress = true;
			}
		}
		
		latitudes.push(location[1]);
		longitudes.push(location[2]);
		
		// get lat and lng and add to the bounds variable
		var point = new google.maps.LatLng(location[1], location[2]);
		bounds.extend(point);
		
		// Build out the marker
		var marker = new google.maps.Marker({
			position: {lat: location[1], lng: location[2]},
			map: map,
			title: location[0]
		});
		
		// build out the content in the info window
		google.maps.event.addListener(marker, 'click', (function(marker, i) {
			var contentString = '<div id="siteNotice"></div>'+
					'<h3>'+ location[0] +'</h3>'+
					'<p class="address">'+ location[4] +'&nbsp;<span>'+ location[5] +'</span>'+
					'&nbsp;<span>'+ location[6] +',&nbsp;'+ location[7] +'&nbsp;'+ location[8] +'</span></p>';
					if(location[9] != null) {contentString += '<p class="phone">'+location[9]+'</p>'; }
			
			return function() {
				infowindow.setContent(contentString);
				infowindow.open(map, marker);
			}
		})(marker, i));
	}
	if (locations.length > 1 && differentAddress) {
		// zoom to the bounds
		map.fitBounds(bounds);
	}

	if($('.CustomProfile .LocationInfo ul.LocationWrap').children().length > 4)	{
		$('.CustomProfile .LocationInfo ul.LocationWrap').addClass('Overflow');
	}
}